import { toast } from 'react-toastify';
import axios  from 'axios';

export function fetchData(url, data) {
	
  if(typeof data === 'object') {

  		return axios.post(url, data)
		  .then(function (response) {

		   if(response.data)
		   	return response.data;
		   else 
		   	return response;

		  })
		  .catch(function (error) {

		    return error;
		    
		  });

  } else {
  	toast('Wrong input type; no FormData', {
			        type: 'error',
			        position: 'top-right'
			    })
  }
}
